export default {
    navigate: {
        home: 'Home',
        pledge: 'Pledge',
        profit: 'Profit',
        team: 'Team',
        link: 'Link Wallet',
    },
    pledge: {
        swapTitle: 'Swap',
        paySubtitle: 'You Pay',
        balance: 'Balance',
        receiveSubtitle: 'You Received',
        allowance: 'ALLOWANCE',
        allowanceProcess: 'ALLOWANCE...',
        exchange: 'EXCHANGE',
        exchanging: 'EXCHANGING...',
        subtitle: 'Pledge KPL to earn profits',
        pledgeTitle: 'Pledge',
        pledge: 'PLEDGE',
        pledgeProcess: 'PLEDGING...',
        price: 'Price',
    },
    profit: {
        registrationTitle: 'Registration Profit',
        stakeTitle: 'Stake Profit',
        teamTitle: 'Team Profit',
        referTitle: 'Refer Profit',
        checkInTitle: 'Check In Profit',
        amount: 'Amount',
        extractedAmount: 'Extracted Amount',
        availableAmount: 'Available Amount',
        extractButtonTitle: 'EXTRACT',
        extractLoadingText: 'EXTRACTING...'
    },
    team: {
        inviteTitle: 'Invite Friends',
        copyButtonText: 'COPY INVITE LINK',
        referrerTitle: 'My Referrer',
        informationTitle: 'Team Information',
        myStakeText: 'My Stake Amount',
        teamStakeText: 'Team Stake Amount',
        teamMembersText: 'Team Members',
        directMembersText: 'Direct Members',
        checkInText: 'sign',
        checkInRewardsText: 'CHECK IN REWARDS',
        extract: 'EXTRACT PRINCIPAL',
        extracting: 'EXTRACTING...',
        expirationDate:'EXPIRATION DATE',
    },
    message: {
        success: 'success',
        failed: 'failed',
        copySuccess: 'Copy Success',
        bindSuccess: 'Bind Success',
        bindFailed: 'Bind Failed',
        invalidAmount: 'Invalid Amount',
        signed: 'Already checked in today'
    }
}
