<script setup>
import {defineProps, ref} from 'vue'
import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress,
} from "@/assets/chain/contractDetails";
import {DateTimeUtils} from "@/utils/DateTimeUtils";

let stakingContractInstance = undefined;
let web3 = undefined;

const initWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
};

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  user: {
    type: String,
    default: ''
  },
  recordsType: {
    type: String,
    default: ''
  }
})
const records = ref([]);
const loading = ref(false);
const finished = ref(false);
const getRecords = async () => {
  await initWeb3();
  loading.value = true;
  if (props.recordsType === 'stake') {
    const res = await stakingContractInstance.methods.getStakingRewardsRecords(props.user).call();
    res.forEach(item => {
      records.value.push({
        paymentType: Number(item.paymentType),
        amount: Number(web3.utils.fromWei(item.amount, 'ether')),
        createTime: Number(item.createTime)
      })
    })
  } else if (props.recordsType === 'team') {
    const res = await stakingContractInstance.methods.getTeamRewardsRecords(props.user).call();
    res.forEach(item => {
      records.value.push({
        paymentType: Number(item.paymentType),
        amount: Number(web3.utils.fromWei(item.amount, 'ether')),
        createTime: Number(item.createTime)
      })
    })
  } else if (props.recordsType === 'refer') {
    const res = await stakingContractInstance.methods.getReferRewardsRecords(props.user).call();
    res.forEach(item => {
      records.value.push({
        paymentType: Number(item.paymentType),
        amount: Number(web3.utils.fromWei(item.amount, 'ether')),
        createTime: Number(item.createTime)
      })
    })
  } else if (props.recordsType === 'checkIn') {
    const res = await stakingContractInstance.methods.getCheckInRewardsRecords(props.user).call();
    res.forEach(item => {
      records.value.push({
        paymentType: Number(item.paymentType),
        amount: Number(web3.utils.fromWei(item.amount, 'ether')),
        createTime: Number(item.createTime)
      })
    })
  }
  loading.value = false;
  finished.value = true;
  if (records.value.length > 1) {
    records.value.sort(function (a, b) {
      return b.createTime - a.createTime;
    })
  }
  console.log(records.value);
}

const onClickLeft = () => history.back();

</script>

<template>
  <div class="records">
    <div class="nav">
      <van-nav-bar :title="title" left-arrow @click-left="onClickLeft"/>
    </div>
    <div class="main">
      <van-list
          v-model:loading="loading"
          :finished=finished
          @load="getRecords"
      >
        <div class="record-card" v-for="(item,index) in records" :key="index">
          <span style="color: gray; font-size: 14px">{{ DateTimeUtils.formatTimestamp(item.createTime) }}</span>
          <span v-if="item.paymentType===0" style="color: green; font-size: 14px">+ {{ item.amount.toFixed(2) }}</span>
          <span v-else style="color: darkred; font-size: 14px">- {{ item.amount.toFixed(2) }}</span>
        </div>
      </van-list>
    </div>
  </div>
</template>

<style scoped lang="less">
.records {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;
    margin-bottom: 60px;

    .record-card {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
      box-sizing: border-box;
      border-bottom: 1px dashed gray;
    }
  }


}
</style>
