<template>
  <div class="team">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress"/>
    </div>
    <div class="main">
      <div class="sign-box">
        <span class="rewards">{{ t('team.checkInRewardsText') }}: {{ rewards.checkInRewards.toFixed(2) }}</span>
        <van-button
            size="mini"
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            :loading="checkInLoading"
            loading-size="14"
            @click="handleCheckIn">
          {{ t('team.checkInText') }}
        </van-button>
      </div>
      <div class="share-box">
        <span class="title">{{ t('team.inviteTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <span class="link">{{ inviteLink }}</span>
        <div class="copy-btn">
          <van-button
              round
              color="#9d00ec"
              block
              @click="copyInviteUrl"
          >{{ t('team.copyButtonText') }}
          </van-button>
        </div>
      </div>
      <div class="share-box" v-if="account.referrer">
        <span class="title">{{ t('team.referrerTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <span class="link">{{ account.referrer }}</span>
      </div>
      <div class="card-box">
        <span class="title">{{ t('team.informationTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <div class="record-box">
          <span class="subtitle">{{ t('team.myStakeText') }}</span>
          <div class="amount-box">
            <span class="amount">{{ account.totalInvested.toFixed(2) }} KPL</span>
          </div>
        </div>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box">
          <span class="subtitle">{{ t('team.teamStakeText') }}</span>
          <div class="amount-box">
            <span class="amount">{{ account.teamStakeAmount.toFixed(2) }} KPL</span>
          </div>
        </div>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box">
          <span class="subtitle">{{ t('team.teamMembersText') }}</span>
          <div class="amount-box">
            <span class="amount">{{ teamMemberCounts }}</span>
          </div>
        </div>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box">
          <span class="subtitle">{{ t('team.directMembersText') }}</span>
          <div class="amount-box">
            <span class="amount">{{ directMemberCounts }}</span>
          </div>
        </div>
        <van-divider v-if="account.totalInvested > 0" :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box" v-if="account.totalInvested > 0">
          <span class="subtitle">{{ t('team.expirationDate') }}</span>
          <div class="amount-box">
            <span class="amount">{{
                DateTimeUtils.formatTimestamp(calculateNextWithdrawalTimestamp(account.startTime))
              }}</span>
          </div>
        </div>
        <div class="extract-btn">
          <van-button
              round
              color="#9d00ec"
              block
              :loading="extractLoading"
              :loading-text="t('team.extracting')"
              @click="handleExtract"
          >{{ t('team.extract') }}
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress,
} from "@/assets/chain/contractDetails";
import WalletUtils from "@/utils/WalletUtils";
import {DateTimeUtils} from "@/utils/DateTimeUtils";
import {onMounted, ref} from "vue";
import {showFailToast, showSuccessToast} from "vant";
import {useI18n} from 'vue-i18n';

const {t} = useI18n({useScope: 'global'});

const walletAddress = ref(undefined);

let stakingContractInstance = undefined;
let web3 = undefined;

const initWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
};

const getAddress = async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
};

const checkInLoading = ref(false);
const handleCheckIn = async () => {
  try {
    checkInLoading.value = true;
    const res = await stakingContractInstance.methods.canCheckIn(walletAddress.value).call();
    if (!res) {
      showFailToast(t('message.signed'));
      checkInLoading.value = false;
      return;
    }
    await stakingContractInstance.methods.checkIn().send({from: walletAddress.value});
    checkInLoading.value = false;
    showSuccessToast(t('message.success'));
    await getRewards();
  } catch (error) {
    console.log(error);
    checkInLoading.value = false;
    showFailToast(t('message.failed'));
  }
}

const extractLoading = ref(false);
const handleExtract = async () => {
  try {
    extractLoading.value = true;
    await stakingContractInstance.methods.extractPrincipal().send({from: walletAddress.value});
    showSuccessToast(t('message.success'));
    extractLoading.value = false;
  } catch (error) {
    console.log(error);
    extractLoading.value = false;
    showFailToast(t('message.failed'));
  }
}

const teamMemberCounts = ref(0);
const directMemberCounts = ref(0);
const account = ref({
  referrer: undefined,
  totalInvested: 0,
  teamStakeAmount: 0,
  startTime: 0,
  level: 0,
  subordinates: []
})

const rewards = ref({
  checkInRewards: 0,
  extractedCheckInRewards: 0,
  teamRewards: 0,
  extractedTeamRewards: 0,
  referRewards: 0,
  extractedReferRewards: 0,
  stakingRewards: 0,
  extractedStakingRewards: 0,
  registrationRewards: 0
})
const getRewards = async () => {
  const res = await stakingContractInstance.methods.rewards(walletAddress.value).call();
  rewards.value.checkInRewards = Number(web3.utils.fromWei(res.checkInRewards, 'ether'));
  rewards.value.extractedCheckInRewards = Number(web3.utils.fromWei(res.extractedCheckInRewards, 'ether'));
  rewards.value.teamRewards = Number(web3.utils.fromWei(res.teamRewards, 'ether'));
  rewards.value.extractedTeamRewards = Number(web3.utils.fromWei(res.extractedTeamRewards, 'ether'));
  rewards.value.referRewards = Number(web3.utils.fromWei(res.referRewards, 'ether'));
  rewards.value.extractedReferRewards = Number(web3.utils.fromWei(res.extractedReferRewards, 'ether'));
  rewards.value.stakingRewards = Number(web3.utils.fromWei(res.stakingRewards, 'ether'));
  rewards.value.extractedStakingRewards = Number(web3.utils.fromWei(res.extractedStakingRewards, 'ether'));
  rewards.value.registrationRewards = Number(web3.utils.fromWei(res.registrationRewards, 'ether'));
}

const getAccountInfo = async () => {
  const team = await stakingContractInstance.methods.teamMemberCounts(walletAddress.value).call();
  teamMemberCounts.value = Number(team);
  const direct = await stakingContractInstance.methods.directMemberCounts(walletAddress.value).call();
  directMemberCounts.value = Number(direct);
  const res = await stakingContractInstance.methods.accounts(walletAddress.value).call();
  account.value.referrer = res.referrer;
  account.value.level = Number(res.level);
  account.value.startTime = Number(res.startTime);
  account.value.totalInvested = Number(web3.utils.fromWei(res.totalInvested, 'ether'));
  account.value.teamStakeAmount = Number(web3.utils.fromWei(res.teamStakeAmount, 'ether'));
  console.log(account.value.startTime);
}

function calculateNextWithdrawalTimestamp(initialTimestamp) {
  const cycleDuration = 24 * 60 * 60; // 24 hours in seconds
  const withdrawalWindow = 1 * 60 * 60; // 1 hour in seconds

  const now = Math.floor(Date.now() / 1000); // Get the current timestamp in seconds

  // First withdrawal time is exactly 24 hours after the initial deposit
  let nextTimestamp = initialTimestamp + cycleDuration;

  // If the current time is within the first cycle, return the first withdrawal time
  if (now < nextTimestamp) {
    return nextTimestamp;
  }

  // Otherwise, continue to find the next available withdrawal window
  while (now >= nextTimestamp + withdrawalWindow) {
    // Move to the next cycle, including both the cycle duration and the withdrawal window
    nextTimestamp += (cycleDuration + withdrawalWindow);
  }

  return nextTimestamp; // Return the next available withdrawal time in seconds
}

const INVITE_PREFIX = ref('https://app.kepler-ai.cyou/?inviteAddress=');
const inviteLink = ref('')
const copyInviteUrl = (() => {
  if (!walletAddress.value) return;
  let copy_ele = document.getElementById("copy");
  let body_ele = document.getElementsByTagName("body")[0];
  if (!copy_ele) {
    copy_ele = document.createElement("input");
    copy_ele.id = "copy";
    copy_ele.type = "text";
    copy_ele.value = INVITE_PREFIX.value + walletAddress.value.trim();
    copy_ele.readOnly = true;
    copy_ele.style.position = "fixed";
    copy_ele.style.zIndex = -1000;
    copy_ele.style.left = `-1000px`;
    body_ele.append(copy_ele);
  }
  copy_ele.select();
  document.execCommand("copy");
  body_ele.removeChild(copy_ele);
  showSuccessToast("链接复制成功");
});
onMounted(async () => {
  await initWeb3();
  await getAddress();
  await getAccountInfo();
  await getRewards();
  inviteLink.value = INVITE_PREFIX.value + walletAddress.value.trim();
})
</script>

<style scoped lang="less">
.team {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;
    margin-bottom: 60px;
    box-sizing: border-box;

    .sign-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 6px;
      border: 1px solid #9d00ec;
      background: rgba(157, 0, 236, 0.40);
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 20px;
      align-items: center;

      .rewards {
        color: white;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .share-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      border: 1px solid #9d00ec;
      background: rgba(157, 0, 236, 0.40);
      padding: 15px;
      box-sizing: border-box;
      margin-bottom: 20px;

      .title {
        width: 100%;
        color: white;
        font-size: 16px;
        font-weight: 700;
      }

      .link {
        width: 100%;
        text-align: center;
        color: white;
        font-size: 14px;
        word-wrap: break-word; /* 旧版本浏览器支持 */
        overflow-wrap: break-word; /* 标准属性 */
      }

      .copy-btn {
        width: 100%;
        margin-top: 20px;
      }
    }

    .card-box {
      width: 100%;
      border-radius: 20px;
      border: 1px solid #9d00ec;
      background: rgba(157, 0, 236, 0.40);
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .title {
        width: 100%;
        color: white;
        font-size: 16px;
        font-weight: 700;
      }

      .record-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .subtitle {
          color: white;
          font-size: 14px;
          font-weight: 400;
        }

        .amount-box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .amount {
            color: white;
            font-size: 14px;
            font-weight: 400;
          }

          .more {
            color: white;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .extract-btn {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>
