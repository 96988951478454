<template>
  <router-view/>

  <van-config-provider theme="dark">
    <van-tabbar active-color="#9d00ec" route>
      <van-tabbar-item replace to="/" icon="home-o">{{t('navigate.home')}}</van-tabbar-item>
      <van-tabbar-item replace to="/exchange" icon="refund-o">{{t('navigate.pledge')}}</van-tabbar-item>
      <van-tabbar-item replace to="/rewards" icon="diamond-o">{{t('navigate.profit')}}</van-tabbar-item>
      <van-tabbar-item replace to="/team" icon="cluster-o">{{t('navigate.team')}}</van-tabbar-item>
<!--      <van-tabbar-item replace to="/trade" icon="chart-trending-o">KPL</van-tabbar-item>-->
    </van-tabbar>
  </van-config-provider>

</template>

<style lang="less">
</style>
<script setup>

import {useI18n} from 'vue-i18n';

const {t} = useI18n({useScope: 'global'});

</script>
