export default {
    navigate: {
        home: '首页',
        pledge: '质押',
        profit: '收益',
        team: '团队',
        link: '链接钱包',
    },
    pledge: {
        swapTitle: '兑换',
        paySubtitle: '您支付',
        balance: '余额',
        receiveSubtitle: '您接收',
        allowance: '授权',
        allowanceProcess: '授权中...',
        exchange: '兑换',
        exchanging: '兑换中...',
        subtitle: '质押KPL赢取收益',
        pledgeTitle: '质押',
        pledge: '质押',
        pledgeProcess: '质押中...',
        price: '价格',
    },
    profit: {
        registrationTitle: '注册奖励',
        stakeTitle: '质押收益',
        teamTitle: '团队奖励',
        referTitle: '推荐奖励',
        checkInTitle: '签到奖励',
        amount: '数量',
        extractedAmount: '已提数量',
        availableAmount: '可提数量',
        extractButtonTitle: '提取',
        extractLoadingText: '提取中...'
    },
    team: {
        inviteTitle: '邀请好友',
        copyButtonText: '复制邀请链接',
        referrerTitle: '我的邀请人',
        informationTitle: '团队信息',
        myStakeText: '我的质押',
        teamStakeText: '团队业绩',
        teamMembersText: '成员数量',
        directMembersText: '直推数量',
        checkInText: '签到',
        checkInRewardsText: '签到奖励',
        extract: '提取本金',
        extracting: '提取中...',
        expirationDate:'到期时间',

    },
    message: {
        success: '成功',
        failed: '失败',
        copySuccess: '复制成功',
        bindSuccess: '绑定成功',
        bindFailed: '绑定失败',
        invalidAmount: '无效数量',
        signed: '今日已签到',
    }
}
