<script setup>

</script>

<template>
<div class="trade">

</div>
</template>

<style scoped lang="less">

</style>
