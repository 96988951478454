<template>
  <div class="nav-bar">
    <div class="top">
      <img src="../assets/logo.png" alt=""/>
      <div class="right-box">
        <span>{{ walletAddress === t('navigate.link') ? walletAddress : strUtils.sliceString(walletAddress) }}</span>
        <div class="language">
          <van-popover
              :actions="languageActions"
              @select="onLanguageSelect">
            <template #reference>
              <img class="country" :src="isEnglish ? imageEnglish : imageChinese"/>
            </template>
          </van-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {defineProps, ref} from 'vue'
import StringUtils from "@/utils/StringUtils";
import imageEnglish from "../assets/en.png";
import imageChinese from "../assets/cn.png";
import {useI18n} from 'vue-i18n';

const {locale, t} = useI18n({useScope: 'global'});

defineProps({
  walletAddress: {
    type: String,
    default: 'Link Wallet'
  }
});

const languageActions = ref([
  {text: "EN"},
  {text: "CN"}
]);
const isEnglish = ref(localStorage.getItem('language') === 'en')
const onLanguageSelect = ((action) => {
  isEnglish.value = action.text === 'EN';
  if (action.text === 'EN') {
    locale.value = 'en';
    localStorage.setItem('language', 'en');
  } else {
    locale.value = 'cn';
    localStorage.setItem('language', 'cn');
  }
})

const strUtils = new StringUtils();


</script>
<style scoped lang="less">
/deep/ .van-popover__wrapper {
  display: block;
}

/deep/ .van-popover__action {
  width: auto;
}

.nav-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;


  .top {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      height: 40px;
    }

    .right-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        padding: 5px 10px;
        font-size: 14px;
        border-radius: 4px;
        height: 30px;
        border: 1px solid #9d00ec;
        color: #9d00ec;
        font-weight: bold;
        align-items: center;
        box-sizing: border-box;
      }

      .language {
        margin-left: 10px;
        width: 30px;
        height: 30px;
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid #9d00ec;
        border-radius: 4px;
        background: rgba(157, 0, 236, 0.45);

        .country {
          height: 18px;
          width: 18px;
          border-radius: 10px;
          display: block;
        }
      }
    }


  }

}
</style>
