<template>
  <div class="home">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress"/>
    </div>
    <div class="main">
      <div class="container">

        <div class="notice-title">
          Swap Quickly, Audit Securely
        </div>
        <div class="notice-content">
          Unlock the magic of seamless crypto transactions and reliable token monitoring with Kepler. Our platform
          brings you two extraordinary products designed to enhance your crypto experience like never before.
        </div>

        <div class="card-box">
          <img src="../assets/kpl-icon.png"/>
          <div class="content-box">
            <div class="title" style="margin-top: 20px">KEPLER</div>
          </div>
        </div>

        <div class="subtitle">
          Multi-Swap Features you need to know
        </div>
        <div class="card-box">
          <img src="../assets/card-image.webp"/>
          <div class="content-box">
            <div class="title">Multi-Network Swaps</div>
            <div class="content">Exchange tokens across different networks effortlessly.</div>
          </div>
        </div>
        <div class="card-box" style="margin-bottom: 60px">
          <img src="../assets/card-image5.webp"/>
          <div class="content-box">
            <div class="title">Optimal Routes</div>
            <div class="content">See all possible routes for your swap and choose the best one, or let Kepler
              automatically select the most efficient path for you.
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog
        v-model:show="bindDialogShow"
        title="绑定领导人"
        show-cancel-button
        @confirm="handleBindConfirm">
      <van-cell-group inset>
        <van-field
            v-model="leaderAddress"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入领导人钱包地址"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script setup>
import NavBar from "@/components/NavBar.vue";

import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress
} from "@/assets/chain/contractDetails";
import WalletUtils from "@/utils/WalletUtils";
import {closeToast, showFailToast, showLoadingToast, showSuccessToast} from "vant";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();

const walletAddress = ref(undefined);

const leaderAddress = ref(undefined);
const bindDialogShow = ref(false);

let stakingContractInstance = undefined;
let web3 = undefined;

const initWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
};

const getAddress = async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
};

const checkBind = async () => {
  const inviteAddress = route.query.inviteAddress;
  if (inviteAddress) {
    leaderAddress.value = inviteAddress;
    const res = await stakingContractInstance.methods.accounts(walletAddress.value).call();
    if (res.isBound) {
      return;
    }
    bindDialogShow.value = true;
  }
};

const handleBindConfirm = async () => {
  try {
    if (!leaderAddress.value && !web3.utils.isAddress(leaderAddress.value)) {
      showFailToast("请输入正确的钱包地址");
      return;
    }
    showLoadingToast({
      message: '绑定中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    });
    await stakingContractInstance.methods.bindReferrer(leaderAddress.value).send({from: walletAddress.value});
    closeToast();
    showSuccessToast("绑定成功");
  } catch (error) {
    closeToast();
    if (error.code === 4001) {
      showFailToast("取消绑定");
      return;
    }
    if (error.code === -32603) {
      showFailToast("绑定失败");
      return;
    }
    showFailToast("绑定失败");
  }
}


onMounted(async () => {
  await initWeb3();
  await getAddress();
  await checkBind();
})

</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .notice-title {
        margin: 0;
        padding: 0;
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        color: white;
        margin-block-start: 0.67em;
        margin-block-end: 0.2em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        unicode-bidi: isolate;
      }

      .notice-content {
        margin: 0 0 30px 0;
        padding: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #75778b;
        display: block;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        unicode-bidi: isolate;
        text-align: center;
      }

      .subtitle {
        width: 100%;
        font-size: 30px;
        font-weight: 400;
        color: white;
        text-align: center;
        margin: 20px 0;
      }

      .card-box {
        width: 100%;
        border-radius: 32px;
        background: #0d0e13;
        justify-content: space-around;
        text-align: center;
        margin-bottom: 20px;

        img {
          width: 100%;
          border-radius: 32px;
        }

        .content-box {
          width: 100%;
          padding: 0 20px 20px 20px;
          box-sizing: border-box;

          .title {
            color: white;
            font-weight: 400;
            font-size: 28px;
          }

          .content {
            margin-top: 10px;
            font-weight: 400;
            font-size: 16px;
            color: rgba(255, 255, 255, .48);
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
