<script setup>

import RewardsRecord from "@/components/RewardsRecord.vue";
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";

const route = useRoute()
const params = ref({
  title: undefined,
  user: undefined,
  type: undefined
})
onMounted(() => {
  params.value.title = route.query.title;
  params.value.user = route.query.user;
  params.value.type = route.query.type;
})
</script>

<template>
  <RewardsRecord :title="params.title" :user="params.user" :records-type="params.type"/>
</template>

<style scoped lang="less">

</style>
