import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TeamView from "@/views/TeamView.vue";
import ExchangeView from "@/views/ExchangeView.vue";
import RewardsView from "@/views/RewardsView.vue";
import TradeView from "@/views/TradeView.vue";
import RecordsView from "@/views/RecordsView.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/team',
        name: 'team',
        component: TeamView
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: ExchangeView
    },
    {
        path: '/trade',
        name: 'trade',
        component: TradeView
    },
    {
        path: '/rewards',
        name: 'rewards',
        component: RewardsView
    },
    {
        path: '/records',
        name: 'records',
        component: RecordsView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
