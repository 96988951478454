<script setup>

import NavBar from "@/components/NavBar.vue";

import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress,
} from "@/assets/chain/contractDetails";
import WalletUtils from "@/utils/WalletUtils";
import {onMounted, ref} from "vue";
import {showFailToast, showSuccessToast} from "vant";
import {useI18n} from 'vue-i18n';
import {useRouter} from "vue-router";

const router = useRouter();

const {t} = useI18n({useScope: 'global'});

const walletAddress = ref(undefined);

let stakingContractInstance = undefined;
let web3 = undefined;

const initWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
};

const getAddress = async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
};

const rewards = ref({
  checkInRewards: 0,
  extractedCheckInRewards: 0,
  teamRewards: 0,
  extractedTeamRewards: 0,
  referRewards: 0,
  extractedReferRewards: 0,
  stakingRewards: 0,
  extractedStakingRewards: 0,
  registrationRewards: 0
})
const getRewards = async () => {
  const res = await stakingContractInstance.methods.rewards(walletAddress.value).call();
  rewards.value.checkInRewards = Number(web3.utils.fromWei(res.checkInRewards, 'ether'));
  rewards.value.extractedCheckInRewards = Number(web3.utils.fromWei(res.extractedCheckInRewards, 'ether'));
  rewards.value.teamRewards = Number(web3.utils.fromWei(res.teamRewards, 'ether'));
  rewards.value.extractedTeamRewards = Number(web3.utils.fromWei(res.extractedTeamRewards, 'ether'));
  rewards.value.referRewards = Number(web3.utils.fromWei(res.referRewards, 'ether'));
  rewards.value.extractedReferRewards = Number(web3.utils.fromWei(res.extractedReferRewards, 'ether'));
  rewards.value.stakingRewards = Number(web3.utils.fromWei(res.stakingRewards, 'ether'));
  rewards.value.extractedStakingRewards = Number(web3.utils.fromWei(res.extractedStakingRewards, 'ether'));
  rewards.value.registrationRewards = Number(web3.utils.fromWei(res.registrationRewards, 'ether'));
}

const extractLoading = ref(false);
const extractRewards = async (type) => {
  try {
    extractLoading.value = true;
    if (type === 1) {
      if (rewards.value.registrationRewards === 0) {
        showFailToast("Insufficient balance");
        extractLoading.value = false;
        return;
      }
      await stakingContractInstance.methods.extractRegistrationRewards().send({from: walletAddress.value});
    } else if (type === 2) {
      if (rewards.value.stakingRewards === 0) {
        showFailToast("Insufficient balance");
        extractLoading.value = false;
        return;
      }
      await stakingContractInstance.methods.extractStakingRewards().send({from: walletAddress.value});
    } else if (type === 3) {
      if (rewards.value.teamRewards === 0) {
        showFailToast("Insufficient balance");
        extractLoading.value = false;
        return;
      }
      await stakingContractInstance.methods.extractTeamRewards().send({from: walletAddress.value});
    } else if (type === 4) {
      if (rewards.value.referRewards === 0) {
        showFailToast("Insufficient balance");
        extractLoading.value = false;
        return;
      }
      await stakingContractInstance.methods.extractReferRewards().send({from: walletAddress.value});
    } else if (type === 5) {
      if (rewards.value.checkInRewards === 0) {
        showFailToast("Insufficient balance");
        extractLoading.value = false;
        return;
      }
      await stakingContractInstance.methods.extractCheckInRewards().send({from: walletAddress.value});
    }
    extractLoading.value = false;
    await getRewards();
    showSuccessToast("Extract Success");
  } catch (error) {
    console.log(error);
    extractLoading.value = false;
    showFailToast("Extract Failed");
  }
}

const navigateToRecords = (title, user, type) => {
  console.log(title);
  router.push({path: '/records', query: {title, user, type}})
}

onMounted(async () => {
  await initWeb3();
  await getAddress();
  await getRewards();
});

</script>

<template>
  <div class="rewards">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress"/>
    </div>
    <div class="main">
      <div class="card-box" v-if="rewards.registrationRewards > 0">
        <span class="title">{{ t('profit.registrationTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.amount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.registrationRewards.toFixed(2) }} KPL</span>
          </div>
        </div>
        <div class="extract-btn">
          <van-button
              round
              color="#9d00ec"
              block
              :loading="extractLoading"
              :loading-text="t('profit.extractLoadingText')"
              @click="extractRewards(1)"
          >{{ t('profit.extractButtonTitle') }}
          </van-button>
        </div>
      </div>
      <div class="card-box">
        <span class="title">{{ t('profit.stakeTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.extractedAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.extractedStakingRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.stakeTitle'),walletAddress,'stake')"/>
          </div>
        </div>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.availableAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.stakingRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.stakeTitle'),walletAddress,'stake')"/>
          </div>
        </div>
        <div class="extract-btn">
          <van-button
              round
              color="#9d00ec"
              block
              :loading="extractLoading"
              :loading-text="t('profit.extractLoadingText')"
              @click="extractRewards(2)"
          >{{ t('profit.extractButtonTitle') }}
          </van-button>
        </div>
      </div>

      <div class="card-box">
        <span class="title">{{ t('profit.teamTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.extractedAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.extractedTeamRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.teamTitle'),walletAddress,'team')"/>
          </div>
        </div>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.availableAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.teamRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.teamTitle'),walletAddress,'team')"/>
          </div>
        </div>
        <div class="extract-btn">
          <van-button
              round
              color="#9d00ec"
              block
              :loading="extractLoading"
              :loading-text="t('profit.extractLoadingText')"
              @click="extractRewards(3)"
          >{{ t('profit.extractButtonTitle') }}
          </van-button>
        </div>
      </div>

      <div class="card-box">
        <span class="title">{{ t('profit.referTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.extractedAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.extractedReferRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.referTitle'),walletAddress,'refer')"/>
          </div>
        </div>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.availableAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.referRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.referTitle'),walletAddress,'refer')"/>
          </div>
        </div>
        <div class="extract-btn">
          <van-button
              round
              color="#9d00ec"
              block
              :loading="extractLoading"
              :loading-text="t('profit.extractLoadingText')"
              @click="extractRewards(4)"
          >{{ t('profit.extractButtonTitle') }}
          </van-button>
        </div>
      </div>

      <div class="card-box">
        <span class="title">{{ t('profit.checkInTitle') }}</span>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}"/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.extractedAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.extractedCheckInRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.checkInTitle'),walletAddress,'checkIn')"/>
          </div>
        </div>
        <van-divider :style="{color:'#9d00ec',borderColor: '#9d00ec'}" dashed/>
        <div class="record-box">
          <span class="subtitle">{{ t('profit.availableAmount') }}</span>
          <div class="amount-box">
            <span class="amount">{{ rewards.checkInRewards.toFixed(2) }} KPL</span>
            <van-icon name="arrow" size="16" color="#FFFFFF"
                      @click="navigateToRecords(t('profit.checkInTitle'),walletAddress,'checkIn')"/>
          </div>
        </div>
        <div class="extract-btn">
          <van-button
              round
              color="#9d00ec"
              block
              :loading="extractLoading"
              :loading-text="t('profit.extractLoadingText')"
              @click="extractRewards(5)"
          >{{ t('profit.extractButtonTitle') }}
          </van-button>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="less">
.rewards {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;
    margin-bottom: 60px;

    .card-box {
      width: 100%;
      border-radius: 20px;
      border: 1px solid #9d00ec;
      background: rgba(157, 0, 236, 0.40);
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .title {
        width: 100%;
        color: white;
        font-size: 16px;
        font-weight: 700;
      }

      .record-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .subtitle {
          color: white;
          font-size: 14px;
          font-weight: 400;
        }

        .amount-box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .amount {
            color: white;
            font-size: 14px;
            font-weight: 400;
          }

          .more {
            color: white;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .extract-btn {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

</style>
