<template>
  <div class="exchange">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress"/>
    </div>
    <div class="main">
      <div class="container">
        <div class="card-box">
          <div class="header">
            <span class="title">{{ t('pledge.swapTitle') }}</span>
            <span class="price">{{ t('pledge.price') }}: 0.00003 USDT</span>
          </div>
          <van-divider/>
          <div class="exchange-box">
            <span class="subtitle">{{ t('pledge.paySubtitle') }}</span>
            <div class="exchange-area">
              <input class="usdt-input" v-model="exchangeAmount" @change="handleExchangeInputChange"
                     placeholder="0.00"/>
              <div class="token-box">
                <img src="../assets/usdt.png">
                <span class="coin-name">USDT</span>
              </div>
            </div>
            <span class="price">{{ t('pledge.balance') }}: {{ usdtBalance.toFixed(2) }} USDT</span>
          </div>
          <van-divider/>
          <div class="exchange-box">
            <span class="subtitle">{{ t('pledge.receiveSubtitle') }}</span>
            <div class="exchange-area">
              <input class="usdt-input" v-model="targetAmount" readonly/>
              <div class="token-box">
                <img src="../assets/kpl-icon.png">
                <span class="coin-name">&nbsp;&nbsp;KPL&nbsp;&nbsp;</span>
              </div>
            </div>
          </div>
          <div class="exchange-btn">
            <van-button
                v-if="usdtAllowance == 0"
                round
                color="#9d00ec"
                block
                :loading="usdtAllowanceLoading"
                :loading-text="t('pledge.allowanceProcess')"
                @click="approveUsdtTokens"
            >{{ t('pledge.allowance') }}
            </van-button>
            <van-button
                v-else
                round
                color="#9d00ec"
                block
                :loading="exchangeLoading"
                :loading-text="t('pledge.exchanging')"
                @click="handleExchange"
            >{{ t('pledge.exchange') }}
            </van-button>
          </div>
        </div>
        <div class="main-title">{{ t('pledge.subtitle') }}</div>
        <div class="card-box" style="margin-bottom: 60px">
          <span style="font-size: 24px; font-weight: 400; color: white">{{ t('pledge.pledgeTitle') }}</span>
          <van-divider/>
          <div class="exchange-box">
            <span class="subtitle">{{ t('pledge.paySubtitle') }}</span>
            <div class="exchange-area">
              <input class="usdt-input" v-model="pledgeAmount" placeholder="0.00"/>
              <div class="token-box">
                <img src="../assets/kpl-icon.png">
                <span class="coin-name"> &nbsp;&nbsp;KPL&nbsp;&nbsp;</span>
              </div>
            </div>
            <span class="price">{{ t('pledge.balance') }}: {{ kplBalance.toFixed(2) }} KPL</span>
          </div>
          <div class="exchange-btn" style="margin-top: 20px">
            <van-button
                v-if="kplAllowance == 0"
                round
                color="#9d00ec"
                block
                :loading="kplAllowanceLoading"
                :loading-text="t('pledge.allowanceProcess')"
                @click="approveKplTokens"
            >{{ t('pledge.allowance') }}
            </van-button>
            <van-button
                v-else
                round
                color="#9d00ec"
                block
                :loading="pledgeLoading"
                :loading-text="t('pledge.pledgeProcess')"
                @click="handlePledge"
            >{{ t('pledge.pledge') }}
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import Web3 from "web3";
import {
  tokenContractABI,
  tokenContractAddress,
  stakingTokenContractABI,
  stakingTokenContractAddress,
  stakingContractABI,
  stakingContractAddress,
} from "@/assets/chain/contractDetails";
import WalletUtils from "@/utils/WalletUtils";
import {onMounted, ref} from "vue";
import {showFailToast, showSuccessToast} from "vant";
import {useI18n} from 'vue-i18n';

const {t} = useI18n({useScope: 'global'});

const walletAddress = ref(undefined);

let stakingContractInstance = undefined;
let tokenContractInstance = undefined;
let stakingTokenContractInstance = undefined;
let web3 = undefined;

const initWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
    tokenContractInstance = new window.web3.eth.Contract(tokenContractABI, tokenContractAddress);
    stakingTokenContractInstance = new window.web3.eth.Contract(stakingTokenContractABI, stakingTokenContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
};

const getAddress = async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
};

const usdtAllowance = ref(0);
const kplAllowance = ref(0);

const usdtAllowanceLoading = ref(false);
const kplAllowanceLoading = ref(false);
const updateUsdtAllowance = async () => {
  const res = await tokenContractInstance.methods
      .allowance(walletAddress.value, stakingContractInstance.options.address)
      .call();
  usdtAllowance.value = web3.utils.fromWei(res, "ether");
};

const updateKplAllowance = async () => {
  const res = await stakingTokenContractInstance.methods
      .allowance(walletAddress.value, stakingContractInstance.options.address)
      .call();
  kplAllowance.value = web3.utils.fromWei(res, "ether");
};

const exchangeRate = ref(0);
const getExchangeRate = async () => {
  const res = await stakingContractInstance.methods.tokenPrice().call();
  exchangeRate.value = 1 / Number(web3.utils.fromWei(res, 'ether'));
  console.log(exchangeRate.value);
}

const targetAmount = ref(0)
const handleExchangeInputChange = () => {
  targetAmount.value = (exchangeRate.value * exchangeAmount.value).toFixed(2);
}

const approveUsdtTokens = async () => {
  try {
    usdtAllowanceLoading.value = true;
    await tokenContractInstance.methods
        .approve(stakingContractInstance.options.address, web3.utils.toWei('30000', "ether"))
        .send({from: walletAddress.value});
    await updateUsdtAllowance();
    usdtAllowanceLoading.value = false;
    showSuccessToast(t('message.success'));
  } catch (error) {
    usdtAllowanceLoading.value = false;
    if (error.code === 4001) {
      showFailToast(t('message.failed'));
      return;
    }
    if (error.code === -32603) {
      showFailToast(t('message.failed'));
      return;
    }
    showFailToast(t('message.failed'));
  }
}

const approveKplTokens = async () => {
  try {
    kplAllowanceLoading.value = true;
    await stakingTokenContractInstance.methods
        .approve(stakingContractInstance.options.address, web3.utils.toWei('100000000000', "ether"))
        .send({from: walletAddress.value});
    await updateKplAllowance();
    kplAllowanceLoading.value = false;
    showSuccessToast(t('message.success'));
  } catch (error) {
    kplAllowanceLoading.value = false;
    if (error.code === 4001) {
      showFailToast(t('message.failed'));
      return;
    }
    if (error.code === -32603) {
      showFailToast(t('message.failed'));
      return;
    }
    showFailToast(t('message.failed'));
  }
}

const exchangeAmount = ref(0)
const exchangeLoading = ref(false);
const handleExchange = async () => {
  if (exchangeAmount.value === 0) {
    showFailToast(t('message.invalidAmount'));
    return;
  }
  try {
    exchangeLoading.value = true;
    await stakingContractInstance.methods.exchange(web3.utils.toWei(exchangeAmount.value, 'ether')).send({from: walletAddress.value});
    showSuccessToast(t('message.success'));
    exchangeLoading.value = false;
  } catch (error) {
    exchangeLoading.value = false;
    console.log(error);
    showFailToast(t('message.failed'));
  }
}

const usdtBalance = ref(0);
const kplBalance = ref(0);

const getBalance = async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    const uBalance = await walletUtils.getTokenBalance(tokenContractAddress, walletAddress.value);
    const kBalance = await walletUtils.getTokenBalance(stakingTokenContractAddress, walletAddress.value);
    usdtBalance.value = Number(uBalance);
    kplBalance.value = Number(kBalance);
  } else {
    console.error("获取钱包余额失败");
  }
}

const pledgeAmount = ref(0);
const pledgeLoading = ref(false);
const handlePledge = async () => {
  if (pledgeAmount.value < 3500000) {
    showFailToast(t('message.invalidAmount'))
    return;
  }
  try {
    pledgeLoading.value = true;
    console.log(web3.utils.toWei(pledgeAmount.value, 'ether'))
    await stakingContractInstance.methods.stake(web3.utils.toWei(pledgeAmount.value, 'ether')).send({from: walletAddress.value});
    pledgeLoading.value = false;
    showSuccessToast(t('message.success'));
  } catch (error) {
    console.log(error);
    pledgeLoading.value = false;
    showFailToast(t('message.failed'));
  }
}

onMounted(async () => {
  await initWeb3();
  await getAddress();
  await getExchangeRate();
  await updateUsdtAllowance();
  await updateKplAllowance();
  await getBalance();

})
</script>

<style scoped lang="less">
.exchange {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .main-title {
        width: 100%;
        font-size: 30px;
        font-weight: 400;
        color: white;
        text-align: center;
        margin: 20px 0;
      }

      .card-box {
        width: 100%;
        border-radius: 32px;
        background: rgba(13, 14, 19, 0.77);
        justify-content: space-around;
        padding: 15px;
        margin-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;


        .header {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: end;
          justify-content: space-between;

          .title {
            font-size: 24px;
            font-weight: 400;
            color: white;
          }

          .price {
            font-size: 14px;
            font-weight: 100;
            color: white;
          }
        }

        .exchange-box {
          width: 100%;
          display: flex;
          flex-direction: column;

          .subtitle {
            width: 100%;
            color: gray;
            font-size: 16px;
          }

          .exchange-area {
            padding: 20px 0;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .token-box {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              border-radius: 24px;
              background: rgba(128, 128, 128, 0.51);
              padding: 6px;
              box-sizing: border-box;

              img {
                height: 16px;
                width: 16px;
                border-radius: 12px;
              }

              .coin-name {
                margin-left: 4px;
                color: white;
                font-size: 14px;
                font-weight: 200;
              }
            }

            .usdt-input {
              width: 50%;
              background: none;
              border: none;
              color: white;
              font-size: 30px;
              font-weight: 400;
            }

          }

          .price {
            width: 100%;
            color: #adadad;
            font-size: 16px;
          }
        }

        .exchange-btn {
          width: 100%;
        }
      }
    }
  }

}
</style>
